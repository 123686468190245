import React from 'react';
import '../assets/styles/Home.css';
import logo from '../assets/media/logo.svg';
import bgSquare from '../assets/media/bg-square-tiled-2.png';
// import Stats from 'stats.js';
import {
  Scene, 
  PerspectiveCamera, 
  AmbientLight, 
  PointLight, 
  WebGLRenderer, 
  SphereBufferGeometry, 
  TextureLoader, 
  ShaderMaterial, 
  Clock, 
  PlaneGeometry, 
  MeshStandardMaterial, 
  Mesh
} from 'three'
import {vertexShader, fragmentShader} from "../assets/GLSL/planeShaders.js";
class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading:true
    };
    this.sceneContainer=React.createRef();
    this.webGLCheck=React.createRef();
    this.landingContent=React.createRef();
  }

  initiateSphere = () => {
    const mainGL = this.webGLCheck.current;
    const gl = mainGL.getContext('webgl');
    let getRadians = (degrees) => {
      return degrees*(Math.PI/180);
    }
    if (!gl) {
      return ;
    } else {
      let scene = new Scene();
      let camera = new PerspectiveCamera( 45, window.innerWidth / window.innerHeight, 1, 100 );
      camera.position.z=5;
      let light = new AmbientLight( 0xffffff );
      scene.add( light );
      let clock = new Clock();
      var texture = new TextureLoader().load( bgSquare);
      let planeGeometry = new PlaneGeometry(1000/(10*10), 500/(10*10), 10 , 5);
      let shaderMaterial = new ShaderMaterial({
        vertexShader,
        fragmentShader,
        uniforms: {
          uTime: { value: 0.0 },
          uTexture: {  type: 't', value: texture}
        },
        transparent: true,
        opacity: 0.5
        // wireframe: true,
        // side: DoubleSide
      });
      let planeMesh = new Mesh(planeGeometry, shaderMaterial);
      scene.add(planeMesh);
      var pointLight = new PointLight( 0xff0000, 1, 100 );
      pointLight.position.set( 50, 50, 50 );
      scene.add( pointLight );
      var pointLight2 = new PointLight( 0xff0000, 1, 100 );
      pointLight2.position.set( -50, -50, -50 );
      scene.add( pointLight2 );
      let renderer = new WebGLRenderer({antialias:true});
      renderer.setClearColor("#ffffff");
      renderer.setSize(window.innerWidth,window.innerHeight);
      renderer.setPixelRatio(Math.min(1.5, window.devicePixelRatio));
      renderer.domElement.classList.add('opacityTransition');
      renderer.domElement.style.opacity=0;
      this.sceneContainer.current.appendChild(renderer.domElement);
      let geometry = new SphereBufferGeometry( 0.3, 35, 35 );
      let material = new MeshStandardMaterial( {color: 0x000000} );
      let sphere = new Mesh( geometry, material );
      sphere.rotation.x=0.5;
      let x = 1;
      let ringGeometries = [];
      let ringMeshes = [];
      window.addEventListener('resize', (event) => {
        renderer.setSize(window.innerWidth,window.innerHeight);
        camera.aspect = window.innerWidth/window.innerHeight;
        camera.updateProjectionMatrix();
      })
      // var stats = new Stats();
      // stats.showPanel( 1 ); // 0: fps, 1: ms, 2: mb, 3+: custom
      // document.body.appendChild( stats.dom );
      let renderCount = 0;
      let render = (() => {
        x+=0.25;
        renderCount+=1;
        if (renderCount%2===0) {
          ringMeshes.forEach((ringMesh) => {
            ringMesh.geometry.dispose();
            ringMesh.material.dispose();
            scene.remove(ringMesh);
          })
          ringGeometries = [
            new SphereBufferGeometry( 0.301, 35, 35 , 0 , Math.PI*2,getRadians((0+x)%180) , getRadians(5)),
            new SphereBufferGeometry( 0.301, 35, 35 , 0 , Math.PI*2,getRadians((18+x)%180) , getRadians(5)),
            new SphereBufferGeometry( 0.301, 35, 35 , 0 , Math.PI*2,getRadians((36+x)%180) , getRadians(5)),
            new SphereBufferGeometry( 0.301, 35, 35 , 0 , Math.PI*2,getRadians((54+x)%180) , getRadians(5)),
            new SphereBufferGeometry( 0.301, 35, 35 , 0 , Math.PI*2,getRadians((72+x)%180) , getRadians(5)),
            new SphereBufferGeometry( 0.301, 35, 35 , 0 , Math.PI*2,getRadians((90+x)%180) , getRadians(5)),
            new SphereBufferGeometry( 0.301, 35, 35 , 0 , Math.PI*2,getRadians((108+x)%180) , getRadians(5)),
            new SphereBufferGeometry( 0.301, 35, 35 , 0 , Math.PI*2,getRadians((126+x)%180) , getRadians(5)),
            new SphereBufferGeometry( 0.301, 35, 35 , 0 , Math.PI*2,getRadians((144+x)%180) , getRadians(5)),
            new SphereBufferGeometry( 0.301, 35, 35 , 0 , Math.PI*2,getRadians((162+x)%180) , getRadians(5)),
            new SphereBufferGeometry( 0.301, 35, 35 , 0 , Math.PI*2,getRadians((180+x)%180) , getRadians(5))
          ];
          ringMeshes = ringGeometries.map(ringGeometry => {
            let mesh = new Mesh( ringGeometry, new MeshStandardMaterial( {color: 0xd4af37} ));
            mesh.rotation.x=0.5;
            scene.add(mesh);
            return mesh
          });
          scene.add( sphere );
        }
        // stats.update();
        shaderMaterial.uniforms.uTime.value = clock.getElapsedTime();
        requestAnimationFrame(render);
        renderer.render(scene, camera);
      })
      render();
      setTimeout(() => {
        renderer.domElement.style.opacity=1;
      }, 1000);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // Object.entries(this.props).forEach(([key, val]) =>
    //   prevProps[key] !== val && console.log(`Home's Prop '${key}' changed`)
    // );
    // if (this.state) {
    //   Object.entries(this.state).forEach(([key, val]) =>
    //     prevState[key] !== val && console.log(`Home's State '${key}' changed`)
    //   );
    // }
  }

  updateCSSviewHeight = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  componentDidMount() {
    if (window.gaLoaded) {
      window.ga('send', 'pageview', 'Home');
    }
    this.updateCSSviewHeight();
    window.addEventListener('resize', this.updateCSSviewHeight);
    if (document.fonts) {
      document.fonts.ready.then( ()=> {
        this.setState({
          loading:false
        }, () => {
          setTimeout(() => {
            this.initiateSphere();
            setTimeout(() => {
              this.landingContent.current.style.opacity=1;
            }, 2000);
          }, 500);
        })
      });
    }
  }

  componentWillUnmount() {
  }

  render() {
      return (
        <section label="home-screen" style={{padding:'0'}}>
          <section label="landing-section">
            <div className="loader" style={this.state.loading===true ? {visibility:'visible'}:{visibility:'hidden'}}>
              <div className="loader-circle">
                <div className="loader-line-mask">
                  <div className="loader-line">
                  </div>
                </div>
                <div style={{width:'100%', height:'100%', padding:'40%'}}>
                  <img style={{width:'100%', height:'100%'}} src={logo} alt="" />
                </div>
              </div>
            </div>
            <div ref={this.landingContent} label="landing-content" className="landing-content">
              <div style={{display:'flex', alignItems:'center', paddingLeft:'5%'}} className="home-logo">
                <img style={{height:'60%'}} alt="" src={logo} />
                {/* <p style={{color:'black', fontSize:'22px', textShadow:'-1px -1px 0 #d4af37, 1px -1px 0 #d4af37, -1px 1px 0 #d4af37, 1px 1px 0 #d4af37'}}>Sample Text</p>  */}
                <span>
                </span>
              </div>
              <div style={{textAlign:'center', position:'absolute', width:'100%', top:'50%', left:'50%', transform:'translate(-50%,-50%)'}} className="content-center">
                <p className="text-large" style={{color:'black', textShadow:'-1px -1px 0 #d4af37, 1px -1px 0 #d4af37, -1px 1px 0 #d4af37, 1px 1px 0 #d4af37'}}>HARI SHYAM</p>
                <p style={{color:'black', fontSize:'12px', textShadow:'-1px -1px 0 #d4af37, 1px -1px 0 #d4af37, -1px 1px 0 #d4af37, 1px 1px 0 #d4af37'}}>chao meus magister</p>
              </div>
            </div>
            <canvas ref={this.webGLCheck} style={{display:'none'}}></canvas>
            <div style={{position:'absolute', top:'0', zIndex:'-1'}} ref={this.sceneContainer}>

            </div>
          </section>
        </section>
      );
  }
}

export default Home;
