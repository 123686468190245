import React from 'react';
import './assets/styles/Root.css';
import Home from './screens/Home';

class Root extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidUpdate(prevProps, prevState) {
    // Object.entries(this.props).forEach(([key, val]) =>
    //   prevProps[key] !== val && console.log(`Home's Prop '${key}' changed`)
    // );
    // if (this.state) {
    //   Object.entries(this.state).forEach(([key, val]) =>
    //     prevState[key] !== val && console.log(`Home's State '${key}' changed`)
    //   );
    // }
  }

  componentDidMount() {
    // if (window.gaLoaded) {
    //   window.ga('send', 'pageview', 'About');
    // }
  }

  componentWillUnmount() {
  }

  render() {
      return (
        <div className="root-screen" style={{padding:'0'}}>
          <Home />
        </div>
      );
  }
}

export default Root;
